import './App.scss';
import SocialMediaLinks from './features/SocialMediaLinks';
import CardPreview from './features/card/CardPreview';
import CssCodePanel from './features/code/CssCodePanel';
import HtmlCodePanel from './features/code/HtmlCodePanel';
import OptionCategoryButtons from './features/options/OptionCategoryButtons';
import OptionCategoryPanel from './features/options/OptionCategoryPanel';

export default function App() {



    return (
        <>
            <div className='header'>
                <h1>
                    CSS Card Generator
                </h1>
            </div>

            {/* Main */}
            <div className='main'>
                <SocialMediaLinks/>
                {/* Preview & Options */}
                <div className='preview-options'>
                    {/* Preview */}
                    <div className='preview panel'>
                        {/* Preview Header */}
                        <div className='panel-header'>
                            <h2 className='panel-title'>Preview</h2>
                        </div>
                        {/* Preview Body */}
                        <div className='preview-body'>
                            <CardPreview/>
                        </div>
                    </div>

                    {/* Options*/}
                    <div className='options panel'>
                        {/* Options Header */}
                        <div className='panel-header'>
                            <h2 className='panel-title'>Options</h2>
                        </div>

                        {/* Options Body */}
                        <div className='options-body'>

                            <OptionCategoryButtons/>

                            {/* Option Category Panels */}
                            <div className='option-category-panels'>
                                <OptionCategoryPanel />
                            </div>
                        </div>
                    </div>

                </div>

                {/* Code Panels */}
                <div className='code-panels'>
                        <HtmlCodePanel/>
                        <CssCodePanel/>
                </div>

            </div>


        </>
    );
}