import { Action } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../app/hooks";


interface RangeInputProps {
    label: string,
    value: number,
    actionCreator: (n: number) => Action,
    min: number,
    max: number,
    ariaLabel: string,
    step?: number,  

}


export default function RangeInput({ label, value, actionCreator, min, max, ariaLabel, step }: RangeInputProps) {

    const dispatch = useAppDispatch();

    return (

        <div>
            <label><span>{label} </span>
                <input
                    type="range"
                    value={value}
                    onChange={(e) => dispatch(actionCreator(Number(e.target.value || 0)))}
                    min={min}
                    max={max}
                    aria-label={ariaLabel}
                    style={{ display: 'inline-block', marginLeft: "0.5rem" }}
                    step={step}
                /> {value}px

            </label>
        </div>

    );
}