import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setBodyBgColor, setBodyTextColor, setBodyTitleColor, setBorderColor } from "../card/CardSlice";

export default function ColorsPanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();

    return (
        <div className="option-category-panel">
            <h3 className="title">Colors</h3>
            <div>
                <label>
                    <span>Body Color </span>
                    <input
                        value={card.bodyBgColor}
                        onChange={(e) => dispatch(setBodyBgColor(e.target.value))}
                        type="color"
                        aria-label="color input to adjust the card body color"
                    />
                </label>
            </div>


            {card.isBodyTitleEnabled &&
                <div>
                    <label><span>Title Color </span>
                        <input
                            type="color"
                            value={card.bodyTitleColor}
                            onChange={(e) => dispatch(setBodyTitleColor(e.target.value))}
                            aria-label="color input to adjust title color of the card body"
                        />
                    </label>

                </div>
            }

            <div>
                <label><span>Text Color </span>
                    <input
                        type="color"
                        value={card.bodyTextColor}
                        onChange={(e) => dispatch(setBodyTextColor(e.target.value))}
                        aria-label="color input to adjust text color of the card body"
                    />
                </label>
            </div>



            {card.isBorderWidthEnabled &&
                <div>
                    <label>
                        <span>Border Color </span>
                        <input
                            type="color"
                            value={card.borderColor}
                            onChange={(e) => dispatch(setBorderColor(e.target.value))}
                        />
                    </label>
                </div>
            }

        </div>
    );
}