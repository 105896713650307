import { TwitterShareButton, XIcon, FacebookShareButton, FacebookIcon, TumblrShareButton, TumblrIcon, LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';


export default function SocialMediaLinks() {


    return (
        <div>

            <TwitterShareButton url='https://csscardgenerator.pages.dev/' title='CSS Card Generator' hashtags={["css", "html"]}><XIcon size={32} round={true} /></TwitterShareButton>
            <FacebookShareButton url='https://csscardgenerator.pages.dev/'><FacebookIcon size={30} round={true} /></FacebookShareButton>
            <LinkedinShareButton url='https://csscardgenerator.pages.dev/'  title='CSS Card Generator'><LinkedinIcon size={32} round={true}/></LinkedinShareButton>
            <WhatsappShareButton url='https://csscardgenerator.pages.dev/'  title='CSS Card Generator'><WhatsappIcon size={32} round={true}/></WhatsappShareButton>
            <TumblrShareButton url='https://csscardgenerator.pages.dev/' title='CSS Card Generator'><TumblrIcon size={32} round={true}/></TumblrShareButton>
        </div>
    );
}