import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type OptionCategoryType = "card_border" | "card_size" | "colors" | "card_shadow" | "card_image" | "card_title" | "rounding_corners" | "card_text";

export interface OptionsState {
    selectedOptionCtg : OptionCategoryType
}

const initialState: OptionsState = {
    selectedOptionCtg : "colors", 
} 

export const optionsSlice = createSlice(
    {
        name: "options", 
        initialState,
        reducers: {
            setSelectedOptionCtg : (state, action: PayloadAction<OptionCategoryType>) => {
                state.selectedOptionCtg = action.payload; 
            }
        }
    }
)

export const {setSelectedOptionCtg} = optionsSlice.actions; 
