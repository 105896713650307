import { configureStore } from "@reduxjs/toolkit";
import { optionsSlice } from "../features/options/OptionsSlice";
import { cardSlice } from "../features/card/CardSlice";

export const store = configureStore({
    reducer: {
        options : optionsSlice.reducer,
        card: cardSlice.reducer,
    }
})

export type AppDispatch = typeof store.dispatch; 
export type RootState = ReturnType<typeof store.getState>; 