import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { toggleShadow, setShadowBlurRad, setShadowHPos, setShadowSpreadRad, setShadowVPos } from "../card/CardSlice";
import RangeInput from "../inputs/RangeInput";

export default function CardShadowPanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();

    return (
        <div className="option-category-panel">
            <h3 className="title">Card Shadow</h3>
            <div>
                <label>
                    <input
                        checked={card.isShadowEnabled}
                        onChange={(e) => dispatch(toggleShadow(e.target.checked))}
                        type="checkbox"
                        aria-label="checkbox to add shadow to card"
                    /> Add Shadow
                </label>
            </div>

            {card.isShadowEnabled &&
                <>
                    <RangeInput
                        label="Horizontal Position"
                        value={card.shadowHPos}
                        actionCreator={setShadowHPos}
                        min={-60}
                        max={60}
                        ariaLabel="slider to adjust the horizontal position of the card shadow"
                    />

                    <RangeInput
                        label="Vertical Position"
                        value={card.shadowVPos}
                        actionCreator={setShadowVPos}
                        min={-60}
                        max={60}
                        ariaLabel="slider to adjust the vertical position of the card shadow"
                    />

                    <RangeInput
                        label="Blur Radius"
                        value={card.shadowBlurRad}
                        actionCreator={setShadowBlurRad}
                        min={0}
                        max={60}
                        ariaLabel="slider to adjust the blur radius of the card shadow"
                    />

                    <RangeInput
                        label="Spread Radius"
                        value={card.shadowSpreadRad}
                        actionCreator={setShadowSpreadRad}
                        min={-60}
                        max={60}
                        ariaLabel="slider to adjust the spread radius of the card shadow"
                    />
                </>
            }

        </div>
    );
}