import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setBodyTextColor, setBodyTextFontSize, setBodyTextLineHeight, setIsBodyTextItalic } from "../card/CardSlice";
import RangeInput from "../inputs/RangeInput";

export default function CardTextPanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();

    return (
        <div className="option-category-panel">
            <h3 className="title">Card Text</h3>

            <RangeInput
                label="Font Size"
                actionCreator={setBodyTextFontSize}
                ariaLabel="slider to adjust the font size of the card text"
                max={50}
                min={8}
                value={card.bodyTextFontSize}
            />

            <RangeInput
                label="Line Height"
                actionCreator={setBodyTextLineHeight}
                ariaLabel="slider to adjust the line height of the card text"
                max={4}
                min={1}
                value={card.bodyTextLineHeight}
                step={0.1}
            />

            <div>
                <label><span>Color </span>
                    <input
                        type="color"
                        value={card.bodyTextColor}
                        onChange={(e) => dispatch(setBodyTextColor(e.target.value))}
                        aria-label="color input to adjust text color of the card body"
                    />
                </label>
            </div>

            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={card.isBodyTextItalic}
                        onChange={(e) => dispatch(setIsBodyTextItalic(e.target.checked))}
                        aria-label="checkbox to make the card text style italic"
                    /> Italic
                </label>
            </div>

        </div>
    );
}