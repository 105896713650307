import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import CopyButton from "./CopyButton";


export default function CssCodePanel() {

    const card = useAppSelector((state: RootState) => state.card);

    function removeEmptyLines(input: string): string {
        return input.split('\n').filter(line => line.trim() !== '').join('\n');
    }


    const codeText = `
.card {
    display: flex; 
    flex-direction: column; 
    ${card.isBorderRadiusEnabled ? 
        `border-radius: ${card.borderRadTopLeft}px  ${card.borderRadTopRight}px ${card.borderRadBottomRight}px ${card.borderRadBottomLeft}px;`  : ''}
    ${card.isWidthEnabled ? `width: ${card.width}px;` : ""}
    ${card.isHeightEnabled ? `height: ${card.height}px;` : ""}
    ${card.isShadowEnabled ?
            `box-shadow: ${card.shadowHPos}px ${card.shadowVPos}px ${card.shadowBlurRad}px ${card.shadowSpreadRad}px rgba(0,0,0,0.2);` : ""}
    overflow: hidden; 
    ${card.isBorderWidthEnabled ? `
    border-style: ${card.borderStyle};
    border-width: ${card.borderWidth}px;  
    background-color: ${card.borderColor}; ` : ""}
    border-color: ${card.borderColor};
}
.card-body{
    background-color: ${card.bodyBgColor};
    flex-grow: 1;
    padding: 24px; 
}
${card.isBodyTitleEnabled ? `
.card-title{
    color: ${card.bodyTitleColor};
    font-weight: bold;
    font-size: ${card.bodyTitleFontSize}px;
    font-family: Arial, Helvetica, sans-serif; 
    margin: 0px; 
    padding: 0px; 
    ${card.isBodyTitleItalic ? `
    font-style: italic; 
    `: ""} 
}` : ""}
.card-text{
    line-height: ${card.bodyTextLineHeight};
    margin: 10px 0px 0px 0px; 
    font-size: ${card.bodyTextFontSize}px;
    color: ${card.bodyTextColor};
    ${card.isBodyTextItalic ? `
    font-style: italic; 
    ` : ""}
}
`;

    return (
        <div className="panel">
            <div className="panel-header">
                <h3 className="panel-title">Css Code</h3>
            </div>
            <CopyButton textToCopy={codeText} />
            <SyntaxHighlighter
                wrapLongLines={true}
                language="css"
                style={atomOneDark}
                customStyle={{
                    height: "260px",
                    overflow: "auto",
                    fontFamily: "monospace",
                    fontSize: "0.8rem",
                }}
            >
                {removeEmptyLines(codeText)}
            </SyntaxHighlighter>
        </div>
    );
}