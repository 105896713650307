import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import CardBorderPanel from "./CardBorderPanel";
import CardImagePanel from "./CardImagePanel";
import CardShadowPanel from "./CardShadowPanel";
import CardSizePanel from "./CardSizePanel";
import CardTextPanel from "./CardTextPanel";
import CardTitlePanel from "./CardTitlePanel";
import ColorsPanel from "./ColorsPanel";
import RoundingCornersPanel from "./RoundingCornersPanel";

export default function OptionCategoryPanel() {

    const options = useAppSelector((state: RootState) => state.options);

    switch (options.selectedOptionCtg) {
        case "card_border": {
            return <CardBorderPanel />;
        }
        case "card_size": {
            return <CardSizePanel />;
        }
        case "colors": {
            return <ColorsPanel/>
        }
        case "card_shadow": {
            return <CardShadowPanel/>
        }
        case "card_image": {
            return <CardImagePanel/>
        }
        case "card_title": {
            return <CardTitlePanel/>
        }
        case "rounding_corners": {
            return <RoundingCornersPanel/>
        }
        case "card_text": {
            return <CardTextPanel/>
        }

    }
}