import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setBodyTitleColor, setBodyTitleFontSize, setIsBodyTitleItalic, toggleBodyTitle } from "../card/CardSlice";
import RangeInput from "../inputs/RangeInput";

export default function CardTitlePanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="option-category-panel">
                <h3 className="title">Card Title</h3>

                {/* Body Title Checkbox */}
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={card.isBodyTitleEnabled}
                            onChange={(e) => dispatch(toggleBodyTitle(e.target.checked))}
                            aria-label="checkbox to add title to card body"
                        /> Add Card Title
                    </label>
                </div>

                {card.isBodyTitleEnabled &&
                    <>

                        <div>
                            <label><span>Color </span>
                                <input
                                    type="color"
                                    value={card.bodyTitleColor}
                                    onChange={(e) => dispatch(setBodyTitleColor(e.target.value))}
                                    aria-label="color input to adjust title color of the card body"
                                />
                            </label>
                        </div>

                        <RangeInput
                            label="Font Size"
                            value={card.bodyTitleFontSize}
                            actionCreator={setBodyTitleFontSize}
                            min={8}
                            max={50}
                            ariaLabel="slider to adjust the font size of the card title"
                        />

                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={card.isBodyTitleItalic}
                                    onChange={(e) => dispatch(setIsBodyTitleItalic(e.target.checked))}
                                    aria-label="checkbox to make the title style italic"
                                /> Italic
                            </label>
                        </div>
                    </>
                }

            </div>
        </>
    );
}