import { useDispatch } from "react-redux";
import { setSelectedOptionCtg } from "./OptionsSlice";

export default function OptionCategoryButtons() {

    const dispatch = useDispatch();

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "0.1rem" }}>
            {/* Colors */}
            <div onClick={() => dispatch(setSelectedOptionCtg("colors"))}
            className="option-category-btn">
                Colors
            </div>
            {/* Image */}
            <div onClick={() => dispatch(setSelectedOptionCtg("card_image"))} className="option-category-btn">
                Image
            </div>
            {/* Rounding Corners */}
            <div onClick={() => dispatch(setSelectedOptionCtg("rounding_corners"))} className="option-category-btn">
                Rounding Corners
            </div>
            {/* Card Shadow */}
            <div onClick={() => dispatch(setSelectedOptionCtg("card_shadow"))} className="option-category-btn">
                Card Shadow
            </div>
            {/* Card Border  */}
            <div onClick={() => dispatch(setSelectedOptionCtg("card_border"))} className='option-category-btn'>
                Card Border
            </div>
            {/* Card Size */}
            <div onClick={() => dispatch(setSelectedOptionCtg("card_size"))} className='option-category-btn'>
                Card Size
            </div>
            {/* Card Title */}
            <div onClick={() => dispatch(setSelectedOptionCtg("card_title"))} className="option-category-btn">
                Card Title
            </div>
            {/* Card Text */}
            <div onClick={() => dispatch(setSelectedOptionCtg("card_text"))} className="option-category-btn">
                Card Text
            </div>
        </div>
    );
}