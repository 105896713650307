import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { toggleBorderRadius, setBorderRadTopRight, setBorderRadTopLeft, setBorderRadBottomRight, setBorderRadBottomLeft, setIsBorderRadAllCornersEnabled, setBorderRadAllCorners } from "../card/CardSlice";
import RangeInput from "../inputs/RangeInput";

export default function RoundingCornersPanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();


    return (
        <div className="option-category-panel">
            <h3 className="title">Rounding Corners</h3>

            <label>
                <input
                    type="checkbox"
                    checked={card.isBorderRadiusEnabled}
                    onChange={(e) => dispatch(toggleBorderRadius(e.target.checked))}
                    id="border-raidus-checkbox"
                    aria-label="checkbox to add the border radius attribute to card"
                />
                <span> Border Radius Enabled</span>
            </label>


            {card.isBorderRadiusEnabled &&
                <>
                    <label>
                        <input
                            type="checkbox"
                            checked={card.isBorderRadAllCornersEnabled}
                            onChange={(e) => dispatch(setIsBorderRadAllCornersEnabled(e.target.checked))}

                        />
                        <span> All Corners</span>

                        {card.isBorderRadAllCornersEnabled && 
                            <>
                                <input 
                                    className="range-input"
                                    type="range"
                                    min={1}
                                    max={60}
                                    value={card.borderRadius}
                                    onChange={(e) => dispatch(setBorderRadAllCorners(Number(e.target.value)))}
                                    aria-label="slider to adjust the roundness of the card's all edges"                                    
                                /> {card.borderRadius}px
                            </>
                        }

                    </label>



                    {!card.isBorderRadAllCornersEnabled &&
                        <>
                            <RangeInput
                                label="Top Left"
                                actionCreator={setBorderRadTopLeft}
                                ariaLabel="slider to adjust the roundness of the top left corner of the card"
                                max={60}
                                min={1}
                                value={card.borderRadTopLeft}
                            />

                            <RangeInput
                                label="Top Right"
                                actionCreator={setBorderRadTopRight}
                                ariaLabel="slider to adjust the roundness of the top right corner of the card"
                                max={60}
                                min={1}
                                value={card.borderRadTopRight}
                            />

                            <RangeInput
                                label="Bottom Right"
                                actionCreator={setBorderRadBottomRight}
                                ariaLabel="slider to adjust the roundness of the top right corner of the card"
                                max={60}
                                min={1}
                                value={card.borderRadBottomRight}
                            />

                            <RangeInput
                                label="Bottom Left"
                                actionCreator={setBorderRadBottomLeft}
                                ariaLabel="slider to adjust the roundness of the bottom right corner of the card"
                                max={60}
                                min={1}
                                value={card.borderRadBottomLeft}
                            />

                        </>

                    }


                </>
            }

        </div>
    );
}