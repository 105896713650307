import { PayloadAction, createSlice } from "@reduxjs/toolkit";


export type BorderStyleType = "solid" | "dotted" | "dashed" | "inset" | "outset" | "ridge" | "groove" | "double" | "none" | "hidden";  

export interface CardState {
    isBorderRadiusEnabled: boolean,
    borderRadius: number,
    isWidthEnabled: boolean,
    width: number,
    isHeightEnabled: boolean,
    height: number,
    bodyBgColor: string,
    isShadowEnabled: boolean,
    shadowHPos: number,
    shadowVPos: number,
    shadowBlurRad: number,
    shadowSpreadRad: number,
    isImageEnabled: boolean,
    isBodyTitleEnabled: boolean,
    bodyTitleColor: string,
    bodyTextColor: string,
    isBorderWidthEnabled: boolean,
    borderWidth: number,
    borderColor: string, 
    borderStyle: BorderStyleType,
    bodyTitleFontSize: number, 
    previewImagePath: string, 
    bodyTextFontSize: number, 
    bodyTextLineHeight: number, 
    isBodyTitleItalic: boolean, 
    isBodyTextItalic: boolean, 
    borderRadTopLeft: number, 
    borderRadTopRight: number, 
    borderRadBottomRight: number, 
    borderRadBottomLeft: number, 
    isBorderRadAllCornersEnabled: boolean,

}

const initialState: CardState = {
    isBorderRadiusEnabled: true,
    borderRadius: 16,
    isWidthEnabled: true,
    width: 272,
    isHeightEnabled: false,
    height: 352,
    bodyBgColor: "#fffcfc",
    isShadowEnabled: true,
    shadowHPos: 2,
    shadowVPos: 7,
    shadowBlurRad: 13,
    shadowSpreadRad: 0,
    isImageEnabled: true,
    isBodyTitleEnabled: true,
    bodyTitleColor: "#000000",
    bodyTextColor: "#000000",
    isBorderWidthEnabled: false,
    borderWidth: 2,
    borderColor: "#000000",
    borderStyle: "solid" ,
    bodyTitleFontSize: 18, 
    previewImagePath: "placeholder_300_200.png", 
    bodyTextFontSize: 17, 
    bodyTextLineHeight: 1.4, 
    isBodyTitleItalic: false, 
    isBodyTextItalic: false, 
    borderRadTopLeft: 16, 
    borderRadTopRight: 16, 
    borderRadBottomRight: 16, 
    borderRadBottomLeft: 16, 
    isBorderRadAllCornersEnabled: true,
};


export const cardSlice = createSlice({
    name: "card",
    initialState,
    reducers: {
        toggleBorderRadius: (state, action: PayloadAction<boolean>) => {
            state.isBorderRadiusEnabled = action.payload;
        },
        toggleWidth: (state, action: PayloadAction<boolean>) => {
            state.isWidthEnabled = action.payload;
        },
        setWidth: (state, action: PayloadAction<number>) => {
            state.width = action.payload;
        },
        toggleHeight: (state, action: PayloadAction<boolean>) => {
            state.isHeightEnabled = action.payload;
        },
        setHeight: (state, action: PayloadAction<number>) => {
            state.height = action.payload;
        },
        setBodyBgColor: (state, action: PayloadAction<string>) => {
            state.bodyBgColor = action.payload;
        },
        toggleShadow: (state, action: PayloadAction<boolean>) => {
            state.isShadowEnabled = action.payload;
        },
        setShadowHPos: (state, action: PayloadAction<number>) => {
            state.shadowHPos = action.payload;
        },
        setShadowVPos: (state, action: PayloadAction<number>) => {
            state.shadowVPos = action.payload;
        },
        setShadowBlurRad: (state, action: PayloadAction<number>) => {
            state.shadowBlurRad = action.payload;
        },
        setShadowSpreadRad: (state, action: PayloadAction<number>) => {
            state.shadowSpreadRad = action.payload;
        },
        toggleImage: (state, action: PayloadAction<boolean>) => {
            state.isImageEnabled = action.payload;
        },
        toggleBodyTitle: (state, action: PayloadAction<boolean>) => {
            state.isBodyTitleEnabled = action.payload;
        },
        setBodyTitleColor: (state, action: PayloadAction<string>) => {
            state.bodyTitleColor = action.payload;
        },
        setBodyTextColor: (state, action: PayloadAction<string>) => {
            state.bodyTextColor = action.payload;
        },
        toggleBorderWidth: (state, action: PayloadAction<boolean>) => {
            state.isBorderWidthEnabled = action.payload;
        },
        setBorderWidth: (state, action: PayloadAction<number>) => {
            state.borderWidth = action.payload; 
        },
        setBorderColor: (state, action: PayloadAction<string>) => {
            state.borderColor = action.payload;
        },
        setBorderStyle: (state, action: PayloadAction<BorderStyleType>) => {
            state.borderStyle = action.payload; 
        },
        setBodyTitleFontSize: (state, action: PayloadAction<number>) => {
            state.bodyTitleFontSize = action.payload; 
        },
        setPreviewImagePath: (state, action: PayloadAction<string>) => {
            state.previewImagePath = action.payload; 
        },
        setBodyTextFontSize: (state, action:PayloadAction<number>) => {
            state.bodyTextFontSize = action.payload; 
        },
        setBodyTextLineHeight: (state, action:PayloadAction<number>) => {
            state.bodyTextLineHeight = action.payload; 
        },
        setIsBodyTitleItalic: (state, action: PayloadAction<boolean>) => {
            state.isBodyTitleItalic = action.payload; 
        },
        setIsBodyTextItalic: (state, action: PayloadAction<boolean>) => {
            state.isBodyTextItalic = action.payload; 
        },
        setBorderRadTopLeft: (state, action: PayloadAction<number>) => {
            state.borderRadTopLeft = action.payload; 
        }, 
        setBorderRadTopRight: (state, action: PayloadAction<number>) => {
            state.borderRadTopRight = action.payload; 
        }, 
        setBorderRadBottomRight: (state, action: PayloadAction<number>) => {
            state.borderRadBottomRight = action.payload; 
        }, 
        setBorderRadBottomLeft: (state, action: PayloadAction<number>) => {
            state.borderRadBottomLeft = action.payload; 
        }, 
        setIsBorderRadAllCornersEnabled: (state, action: PayloadAction<boolean>) => {
            state.isBorderRadAllCornersEnabled = action.payload; 
        },
        setBorderRadAllCorners: (state, action: PayloadAction<number>) => {
            state.borderRadius = action.payload; 
            state.borderRadTopLeft = action.payload; 
            state.borderRadTopRight = action.payload; 
            state.borderRadBottomRight = action.payload; 
            state.borderRadBottomLeft = action.payload; 
        }
    }
});

export const { toggleBorderRadius, toggleWidth, setWidth, toggleHeight, setHeight, setBodyBgColor, toggleShadow, setShadowHPos, setShadowVPos, setShadowBlurRad, setShadowSpreadRad, toggleImage, toggleBodyTitle, setBodyTitleColor, setBodyTextColor, toggleBorderWidth, setBorderWidth, setBorderColor, setBorderStyle, setBodyTitleFontSize, setPreviewImagePath, setBodyTextFontSize, setBodyTextLineHeight, setIsBodyTitleItalic, setIsBodyTextItalic, setBorderRadTopLeft, setBorderRadTopRight, setBorderRadBottomRight, setBorderRadBottomLeft, setIsBorderRadAllCornersEnabled, setBorderRadAllCorners } = cardSlice.actions;



