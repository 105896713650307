import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";



export default function CardPreview() {

    const card = useAppSelector((state: RootState) => state.card);

    return (
        <>
            {/* Card */}
            <div style={{
                display: "flex",
                flexDirection: "column",
                // borderRadius: card.isBorderRadiusEnabled ? `${card.borderRadius}px` : 0,
                borderRadius: card.isBorderRadiusEnabled ?  `${card.borderRadTopLeft}px ${card.borderRadTopRight}px ${card.borderRadBottomRight}px ${card.borderRadBottomLeft}px` : 0, 
                width: card.isWidthEnabled ? `${card.width}px` : "auto",
                height: card.isHeightEnabled ? `${card.height}px` : "auto",
                boxShadow: card.isShadowEnabled ?
                    `${card.shadowHPos}px ${card.shadowVPos}px ${card.shadowBlurRad}px ${card.shadowSpreadRad}px rgba(0,0,0,0.2)` : ``,
                overflow: "hidden",
                borderStyle: card.isBorderWidthEnabled ? `${card.borderStyle}` : "none",
                borderWidth: card.isBorderWidthEnabled ? `${card.borderWidth}px` : "0px",
                backgroundColor: card.isBorderWidthEnabled ? `${card.borderColor}`: "transparent", 
                borderColor: `${card.borderColor}`,
            }}>

                {/* Card Image */}
                {card.isImageEnabled &&
                    <img
                        src={`images/${card.previewImagePath}`}
                        alt="preview img"
                    />
                }

                {/* Card Body */}
                <div style={{
                    backgroundColor: `${card.bodyBgColor}`,
                    flexGrow: "1",
                    padding: "24px",
                }}>
                    {card.isBodyTitleEnabled &&
                        // Card Title 
                        <h2
                            style={{
                                color: `${card.bodyTitleColor}`,
                                fontWeight: "bold",
                                fontSize: `${card.bodyTitleFontSize}px`,
                                fontFamily: "Arial, Helvetica, sans-serif",
                                margin: "0px",
                                padding: "0px",
                                fontStyle: card.isBodyTitleItalic ? 'italic': 'normal',  
                            }}
                        >
                            Card Title
                        </h2>
                    }
                    {/* Card Text */}
                    <p
                        style={{
                            lineHeight: `${card.bodyTextLineHeight}`,
                            margin: "10px 0px 0px 0px",
                            fontSize: `${card.bodyTextFontSize}px`,
                            color: `${card.bodyTextColor}`, 
                            fontStyle: card.isBodyTextItalic ? `italic` : 'normal', 
                        }}
                    >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis esse aliquid hic.</p>
                </div>
            </div>
        </>
    );
}