import { useState } from "react";

export default function CopyButton(prop: { textToCopy: string; }) {

    const [copyBtnTxt, setCopyBtnTxt] = useState("Copy");

    function handleCopyBtnClick(e: React.MouseEvent<HTMLButtonElement>) {
        navigator.clipboard.writeText(prop.textToCopy);
        setCopyBtnTxt("Copied");
    }


    return (
        <button
            onBlur={() => setCopyBtnTxt("Copy")}
            onClick={handleCopyBtnClick}
            className= "copy-button"
        >
            {copyBtnTxt}
        </button>
    );
}