import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import CopyButton from "./CopyButton";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";


export default function HtmlCodePanel() {

    const card = useAppSelector((state: RootState) => state.card);


    
    function removeEmptyLines(input: string): string {
        return input.split('\n').filter(line => line.trim() !== '').join('\n');
    }

    const codeText = `<div class="card">
    ${card.isImageEnabled ? `<img src="https://csscardgenerator.pages.dev/images/${card.previewImagePath}" alt="preview img"/>` : ""}
    <div class="card-body">
        ${card.isBodyTitleEnabled ? `<h2 class="card-title">Card Title</h2> ` : "" } 
        <p class="card-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis esse aliquid hic.</p>
    </div>
</div>`;

    return (
        <div className="panel">
            <div className="panel-header">
                <h3 className="panel-title">Html Code</h3>
            </div>
            <CopyButton textToCopy={codeText}/>

            <SyntaxHighlighter
                wrapLongLines={true}
                language="htmlbars"
                style={atomOneDark}
                customStyle={{ 
                    height: "200px", 
                    overflow: "auto", 
                    fontFamily: "monospace", 
                    fontSize: "0.8rem",
                }}
            >
                {removeEmptyLines(codeText)}
            </SyntaxHighlighter>
        </div>
    );
}