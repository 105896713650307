import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { toggleHeight, toggleWidth, setHeight, setWidth } from "../card/CardSlice";

export default function CardSizePanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();

    return (
        <div className="option-category-panel">
            <h3 className="title">Card Size</h3>

            {/* Width */}
            <div>
                <input
                    type="checkbox"
                    checked={card.isWidthEnabled}
                    onChange={(e) => dispatch(toggleWidth(e.target.checked))}
                    id="width-checkbox"
                    aria-label="checkbox to add the width attribute to card"
                />
                <label htmlFor="width-checkbox"> Width</label>

                {card.isWidthEnabled &&
                    <>
                        <input
                            className="range-input"
                            type="range"
                            min={150}
                            max={600}
                            value={card.width}
                            onChange={(e) => dispatch(setWidth(Number(e.target.value || 0)))}
                            aria-label="slider to adjust the width attribute of the card"
                        /> {card.width}px
                    </>
                }
            </div>


            {/* Height */}
            <div>
                <input
                    checked={card.isHeightEnabled}
                    onChange={(e) => dispatch(toggleHeight(e.target.checked))}
                    type="checkbox"
                    id="height-checkbox"
                    aria-label="checkbox to add the height attribute to card"

                />
                <label htmlFor="height-checkbox"> Height</label>

                {card.isHeightEnabled &&
                    <>
                        <input
                            className="range-input"
                            type="range"
                            min={80}
                            max={600}
                            value={card.height}
                            onChange={(e) => dispatch(setHeight(Number(e.target.value || 0)))}
                            aria-label="slider to adjust the height attribute of the card"
                        /> {card.height}px
                    </>
                }
            </div>

        </div>
    );
};