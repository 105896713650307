import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { BorderStyleType, setBorderColor, setBorderStyle, setBorderWidth, toggleBorderWidth } from "../card/CardSlice";
import RangeInput from "../inputs/RangeInput";

export default function CardBorderPanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();

    return (
        <div className="option-category-panel">
            <h3 className="title" >Card Border</h3>

            {/* Border Checkbox */}
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={card.isBorderWidthEnabled}
                        onChange={(e) => dispatch(toggleBorderWidth(e.target.checked))}
                        aria-label="checkbox to add the border width to card"
                    />
                    <span> Add Border</span>
                </label>
            </div>

            {card.isBorderWidthEnabled &&
                <>

                    <RangeInput
                        actionCreator={setBorderWidth}
                        ariaLabel="slider to adjust the border width of the card"
                        label="Width"
                        min={1}
                        max={30}
                        value={card.borderWidth}
                    />

                    <div>
                        <label>
                            <span>Color </span>
                            <input
                                type="color"
                                value={card.borderColor}
                                onChange={(e) => dispatch(setBorderColor(e.target.value))}
                            />
                        </label>
                    </div>


                    <div>
                        <label >
                            <span>Style  </span>
                            <select value={card.borderStyle}
                                onChange={(e) => dispatch(setBorderStyle(e.target.value as BorderStyleType))}>
                                <option value="solid">solid</option>
                                {/* <option value="dotted">dotted</option> */}
                                {/* <option value="dashed">dashed</option> */}
                                <option value="inset">inset</option>
                                <option value="outset">outset</option>
                                <option value="ridge">ridge</option>
                                <option value="groove">groove</option>
                                {/* <option value="double">double</option> */}
                                {/* <option value="none">none</option> */}
                                {/* <option value="hidden">hidden</option> */}
                            </select>
                        </label>
                    </div>



                </>

            }


        </div>
    );
}