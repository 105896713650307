import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setPreviewImagePath, toggleImage } from "../card/CardSlice";

export default function CardImagePanel() {

    const card = useAppSelector((state: RootState) => state.card);
    const dispatch = useAppDispatch();


    return (

        <div className="option-category-panel">
            <h3 className="title">Image</h3>

            <div>
                <label>
                    <input
                        checked={card.isImageEnabled}
                        onChange={(e) => dispatch(toggleImage(e.target.checked))}
                        type="checkbox"
                        aria-label="checkbox to add an image to card"
                    /> Add Image
                </label>
            </div>

            {card.isImageEnabled && 
                <div>
                    <label>
                        <span>Preview Image </span>
                        <select
                            value={card.previewImagePath}
                            onChange={(e) => dispatch(setPreviewImagePath(e.target.value))}
                        >
                            <option value="placeholder_300_200.png">placeholder (300 x 200)</option>
                            <option value="dark_placeholder_300_200.png">dark placeholder (300 x 200)</option>
                            <option value="kitten_600_400.jpg">kitten (600 x 400)</option>
                        </select>
                    </label>

                </div>
            }

        </div>



    );
}